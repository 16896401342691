import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import ArticleStack from '../components/article-stack';
import ArticleCategories from '../components/article-categories';

const Articles = ({ data }) => {
  return (
    <Layout>
      <SEO title="Articles" />
      <div className="blue-radial-background pt-16">
        <div className="min-h-screen p-3 pt-0 md:p-12 md:pt-0 md:pr-9 pr-0">
          <div className="mx-auto max-w-screen-xl mb-12">
            <ArticleCategories className="mt-12" slug="articles" />
            <div className="mt-12 h-1 border-l-8 border-blue-200 bg-blue-700 mr-3 lg:mr-6" />
            <h2 className="pt-4 text-blue-200 text-2xl">
              {data.strapiArticleList.title}
            </h2>
            <h3 className="text-blue-100 text-lg pt-2 font-sans">
              {data.strapiArticleList.description}
            </h3>
          </div>
          <ArticleStack nodes={data.allStrapiArticle.nodes} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        title
        slug
        category {
          name
          slug
        }
        author {
          name
          picture {
            childImageSharp {
              fixed(quality: 90, width: 48, height: 48) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          profileSlug
        }
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        publishedAt(formatString: "MMMM Do, YYYY")
      }
    }
    allStrapiCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    strapiArticleList {
      title
      description
    }
  }
`;

export default Articles;
